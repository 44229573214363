@charset "UTF-8";

@font-face {
  font-family: "custom-icons";
  src:url("fonts/custom-icons.eot");
  src:url("fonts/custom-icons.eot?#iefix") format("embedded-opentype"),
    url("fonts/custom-icons.woff") format("woff"),
    url("fonts/custom-icons.ttf") format("truetype"),
    url("fonts/custom-icons.svg#custom-icons") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "custom-icons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="ta-icon-"]:before,
[class*=" ta-icon-"]:before {
  font-family: "custom-icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ta-icon-filter-edit:before {
  content: "\61";
}
.ta-icon-tag-nfc-1:before {
  content: "\62";
}
.ta-icon-tag-nfc-2:before {
  content: "\63";
}
.ta-icon-tag-nfc:before {
  content: "\64";
}
.ta-icon-exclamation-circle-r:before {
  content: "\65";
}
.ta-icon-exclamation-circle-l:before {
  content: "\66";
}
.ta-icon-loader-duotone:before {
  content: "\67";
}
.ta-icon-check-circle-r:before {
  content: "\68";
}
.ta-icon-nc-ico:before {
  content: "\69";
}
.ta-icon-screwdriver-wrench:before {
  content: "\6a";
}
.ta-icon-star:before {
  content: "\6b";
}
.ta-icon-tower-broadcast:before {
  content: "\6c";
}
.ta-icon-wrench:before {
  content: "\6d";
}
.ta-icon-ban:before {
  content: "\6e";
}
.ta-icon-folder-tree:before {
  content: "\6f";
}
.ta-icon-expand-tree:before {
  content: "\70";
}
.ta-icon-calendar-days:before {
  content: "\71";
}
.ta-icon-clock-r:before {
  content: "\72";
}
